<template>
    <v-dialog v-model="otevreno" width="600" :fullscreen="$vuetify.breakpoint.mobile">

        <v-card class="">

            <div class="text-overline pt-5" style="text-align:center">Upravení parametrů uživatele</div>
            
            <div class="body-1 font-weight-light pa-5" style="text-align:center">
              <p>Po odeslání požadavku dojde ještě ke kontrole. V případě problémů se Vám ozveme.</p>
            </div>

            <v-form class="pa-4" ref="myForm">
              <v-text-field v-model="form.Nazev"  label="Název/jméno" autofocus></v-text-field>
              <v-text-field v-model="form.Adresa"  label="Adresa"></v-text-field>
              <v-text-field v-model="form.Email"  label="Email"></v-text-field>
              <v-text-field v-model="form.Telefon"  label="Telefon"></v-text-field>
              <v-text-field v-model="form.Ico"  label="IČO"></v-text-field>
              <v-switch v-model="form.Novinky" label="Zasílat novinky na email" :false-value="ne" :true-value="ano"></v-switch>

              <v-spacer class="pa-4"></v-spacer>

              <v-btn v-if="!odesilani" @click='odeslat' block>Odeslat</v-btn>
              <div v-if="odesilani" block style="text-align:center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </div>
            </v-form>
            <div class="text-overline pb-4" style="text-align:center" @click="otevreno=false"><a>Zavřít</a></div>
      </v-card>

    </v-dialog>
</template>

<script>
 export default {
    props: ['zobraz','udaje'],
    watch: {
      zobraz() {
        this.otevreno = true
        this.form = this.udaje
        console.log(this.form.Novinky)
      },
    },
    data: () => ({
      odesilani: false,
      otevreno: false,
      // menu: false,
      form: {
      },
      ne: -1,
      ano: 1,

    }),
    
    methods: {

      odeslat() {
        if (this.$refs.myForm.validate()) {
                this.odesilani = true
                let url = process.env.VUE_APP_API_URL + "/pozadavek"
                let options = {
                  method: 'POST',
                  credentials: 'include',
                  headers: {'Accept': 'application/json','Content-Type': 'application/json','Authorization': localStorage.getItem("token")},
                  body: JSON.stringify(this.form)
                }
                fetch(url, options)
                    .then(res => {
                      if (res.status !== 200) {
                          throw new Error("Požadavek se nepodařilo odeslat. Kontaktujte dispečink na tel.: 234 222 222")
                      }
                      return res.json()
                    })
                    .then(() => {
                      // this.$emit('zavrit')
                      this.otevreno = false
                      this.$root.$emit('uspech', "Požadavek byl úspěšně odeslán. O vyřízení Vás bude informovat dispečink.")
                      this.odesilani = false
                    })
                    .catch((err) => {
                        this.$root.$emit('chyba', err.message)
                        this.odesilani = false
                    })

        }
      }
    }

 }
</script>

