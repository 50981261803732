<template>
<!-- Zobrazi zakladni rozvrzeni
  - pokud neni pripojeno (overi existenci tokenu), presmeruje na login
  - router-view zobrazi stranku (faktury, detail klienta, sluzby, smlouvy)
   -->
  <v-app>

    <v-main v-if="!first_contact">

      <v-app-bar flat app>

        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        Zákaznický portál
        <v-subheader><a href="//234.cz">Connect plus s.r.o.</a></v-subheader>
      </v-app-bar>

      <v-navigation-drawer app v-model="drawer">
        <v-list>
          <!-- -->
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                Uživatel
              </v-list-item-title>
              <v-list-item-subtitle>

                <p-username ref="uzivatel"></p-username>

              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          
          <v-divider></v-divider>

        <v-list-item v-for="i in links" :key="i.text" router :to="i.route">
          <v-list-item-icon>
            <v-icon>{{i.icon}}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{i.text}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>


    </v-navigation-drawer>

    <v-container>
      <router-view ref="stranka" />
    </v-container>
    </v-main>

    <p-form-login v-if="show_login" @zavrit="zalogovany"></p-form-login>

    <v-snackbar v-model="snack_show" :color="snack_color" timeout="4000">
      <div style="text-align:center">{{ snack_hlaseni }}</div>
    </v-snackbar>

  </v-app>
</template>

<script>
  export default {
    name: 'App',
    
    data: () => ({
      drawer: true,
      show_login: false,
      snack_show: false,
      snack_color: "error",
      snack_hlaseni: "",
      first_contact: false,
      casovac: new Date(),

      links: [
        {icon: 'mdi-home', text: 'Domů', route: '/'},
        {icon: 'mdi-account', text: 'Uživatel', route: '/uzivatel'},
        {icon: 'mdi-receipt', text: 'Faktury', route: '/faktury'},
        // {icon: 'mdi-file-document', text: 'Smlouvy', route: '/smlouvy'},
        {icon: 'mdi-book', text: 'Smlouvy', route: '/smlouvy'},
        {icon: 'mdi-router-wireless', text: 'Internet', route: '/internet'},
        {icon: 'mdi-television-box', text: 'IPTV', route: '/iptv'},
        {icon: 'mdi-help-box', text: 'Nápověda', route: '/napoveda'},
        {icon: 'mdi-logout', text: 'Odhlásit se', route: '/logout'},
      ]
    }),

    methods: {
      zalogovany() {
        this.show_login=false
        this.first_contact = false
        if ('stranka' in this.$refs) {
          this.$refs.stranka.reload()
        }
        if ('uzivatel' in this.$refs) {
          this.$refs.uzivatel.reload()
        }
      },

    },

    created() {
      if (this.$route.name == "home" && !localStorage.getItem("token")) {
        this.first_contact = true
        this.show_login = true
      }
    },

    mounted() {
      this.$root.$on('neprihlasen', () => {
        this.show_login = true
      }),
      this.$root.$on('chyba', (msg) => {
        this.snack_hlaseni = msg
        this.snack_color = "error"
        this.snack_show = true
      }),
      this.$root.$on('uspech', (msg) => {
        this.snack_hlaseni = msg
        this.snack_color = "success"
        this.snack_show = true
       })

      // Hlidej neaktivitu uzivatele - kazdou minutu over, zda uz neni neaktivni vice nez 30 minut, pak odloguj
      setInterval(
        () => {
          if (new Date().getTime() - this.casovac.getTime() > 1000*60*30) {
            this.show_login = true
            this.casovac = new Date()
          }
        },
        1000*60
      )

      window.addEventListener("click", () => {this.casovac = new Date()})

    }
  }
</script>
