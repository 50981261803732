
<template>
  <div>
    
    <v-container>
    <v-row class="text-center">
      <v-col><h2>Smlouvy</h2></v-col>
    </v-row>
     <v-row class="text-center">
      <v-col>
      <div class="d-flex justify-center">
      <v-checkbox
          v-model="vsechny"
          label="Zobrazit všechny smlouvy"
        ></v-checkbox>
      </div>
      </v-col>
     </v-row>
    </v-container>

    <br>  
    <v-expansion-panels class="mb-6" focusable>
          <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
          ></v-progress-linear>
      <div v-if="filtruj().length == 0"  class="text-overline mb-4"><span v-if="!loading">Žádné položky</span></div>
      <v-expansion-panel v-for="i in filtruj()"
        :key="i.Id">
        <v-expansion-panel-header disable-icon-rotate>
          <v-row >
            <v-col xs="6" md="4">
              <b>{{ i.Nazev }}</b>
            </v-col>
            <v-col xs="6" md="4" class="text--secondary">
              Smlouva: {{ i.SmlouvaKod }}
            </v-col>
            <v-col xs="6" md="4" class="text--secondary">
              Částka: {{ i.CenaCelkem }}&nbsp;Kč
            </v-col>
          </v-row>

          <template v-if="isAktivni(i.PlatiDoData)" v-slot:actions>
            <v-icon color="teal" title="Aktivní">
              <!-- mdi-check -->
              mdi-play-circle
            </v-icon>
          </template>
          <template v-else v-slot:actions>
            <v-icon color="orange" title="Neaktivní">
              mdi-pause-circle
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
        
        <v-row justify="start">
        <v-col xs="12" md="6">
        
        <v-card class="mt-6">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5 mb-1">
                Smlouva: {{ i.SmlouvaKod }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{i.Nazev}}
              </v-list-item-subtitle>


              <div class="text-overline mb-4 mt-4">
                Platí od data: {{ i.PlatiOdData }}
              </div>

              <div class="text-overline mb-4" v-if="i.PlatiDoData">
                Platí do data: {{ i.PlatiDoData }}
              </div>

              <div class="text-overline mb-4">
                Částka: {{ i.CenaCelkem }} Kč
              </div>

              
            </v-list-item-content>
            <!-- <v-list-item-content> -->
            
            <!-- </v-list-item-content> -->
        </v-list-item>
        </v-card>
        </v-col>

        </v-row>

        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

  </div>
</template>

<script>

  export default {
    name: 'SmlouvyView',
    data: () => ({
        smlouvy: [],
        vsechny: false,
        loading: true,
    }),

    mounted() {
      this.loadSmlouvy()
    },

    methods: {
        reload() {
            this.loadSmlouvy()
        },

        loadSmlouvy() {
            let url = process.env.VUE_APP_API_URL + "/smlouvy"
            let options = {
                credentials: 'include',
                headers: {
                'Authorization': localStorage.getItem("token"),
                },
            }
            fetch(url, options)
                .then(res => {
                if (res.status === 401) {
                    this.$root.$emit('neprihlasen')
                    throw new Error("Uživatel odhlášen")
                }
                if (res.status !== 200) {
                    throw new Error("Chyba zpracování požadavku")
                }
                return res.json()
                })
                .then(dat => {
                if (dat.smlouvy) dat.smlouvy.sort((a,b) => {if (a.PlatiOdData < b.PlatiOdData) return 1; else return -1})
                this.smlouvy = dat.smlouvy
                this.loading = false
                })
                .catch((err) => {
                    this.$root.$emit('chyba', err.message)
                })
        },

        filtruj() {
            if (!this.smlouvy) return []
            // if (this.aktivni) return this.smlouvy.filter((v)=>{return this.isAktivni(v.PlatiDoData)})
            // return this.smlouvy
            if (this.vsechny) return this.smlouvy
            return this.smlouvy.filter((v)=>{return this.isAktivni(v.PlatiDoData)})
        },

        isAktivni(doData) {
            if (!doData) return true
            if (doData > new Date().toISOString().slice(0, 10)) return true
            return false
        }

    }

  }
</script>
