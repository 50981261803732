<template>
    <v-dialog v-model="otevreno" fullscreen persistent hide-overlay>

      <v-card tile class="d-flex justify-center align-center">
        <v-card tile class="pa-2" style="min-width: 20em">

            <div>
              <v-img :src="require('../assets/logoConnect.svg')" class="my-3" contain height="200" />
            </div>

            <div class="text-overline" style="text-align:center">Registrace do zákaznického portálu</div>
            
            <div class="body-1 font-weight-light" style="text-align:center">
              <p>
              Registrovat se můžete v případě,<br>
              že máte aktivní alespoň jednu smlouvu.<br>
              Po zadání čísla smlouvy a telefonního <br>
              čísla Vám bude vygenerován přístup<br>
              a heslo Vám přijde jako SMS na telefon.</p>
              <p>Pokud se registrace nepodaří, kontaktujte <br>
              dispečink na tel.: <b>234 222 222</b></p>
            </div>

           <v-form class="pa-4" ref="regForm">
              <v-text-field v-model="smlouva" hint="Číslo Vaší smlouvy" label="Smlouva" :rules="ruleSmlouva" autofocus  v-on:keyup.enter="odeslat"></v-text-field>
              <v-text-field v-model="telefon" hint="Telefon, který jste uvedli při zřizování smlouvy" :rules="ruleTelefon" label="Telefon" v-on:keyup.enter="odeslat"></v-text-field>
              <v-spacer class="pa-4"></v-spacer>

              <v-btn v-if="!odesilani" @click='odeslat' block>Odeslat</v-btn>
              <div v-if="odesilani" block style="text-align:center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </div>
            </v-form>
            <div class="text-overline" style="text-align:center" @click="$emit('zavrit')"><a>Zavřít</a></div>
        </v-card>
      </v-card>

    </v-dialog>
</template>

<script>
 export default {
    data: () => ({
      odesilani: false,
      otevreno: true,
      smlouva: "",
      telefon: "",


      ruleSmlouva: [
        value => !!value || 'Musí být vyplněno.',
        value => {
          const pattern = /^[0-9]{5}[/]?[0-9]{2}[0-9]?$/
          return pattern.test(value) || 'Číslo smlouvy zadejte ve tvaru: 1234567 nebo 12345/12.'
        },
      ],
      ruleTelefon: [
        value => !!value || 'Musí být vyplněno.',
        value => {
          const pattern = /^[0-9]{9}$/
          return pattern.test(value) || 'Telefonní číslo zadejte ve tvaru: 123456789'
        },
      ]
    }),
    
    methods: {
      odeslat() {
        if (this.$refs.regForm.validate()) {
                this.odesilani = true
                let url = process.env.VUE_APP_API_URL + "/register"
                let options = {
                  method: 'POST',
                  headers: {'Accept': 'application/json','Content-Type': 'application/json'},
                  body: JSON.stringify({smlouva: this.smlouva.replace("/",""), telefon: this.telefon})
                }

                fetch(url, options)
                    .then(res => {
                      if (res.status !== 200) {
                          throw new Error("Registrace se nepodařila. Kontaktujte dispečink na tel.: 234 222 222")
                      }
                      return res.json()
                    })
                    .then(() => {
                      this.$emit('zavrit')
                      this.$root.$emit('uspech', "Registrace proběhla úspěšně. Na tel. Vám přijdou přihlašovací údaje.")
                      this.odesilani = false
                    })
                    .catch((err) => {
                        this.$root.$emit('chyba', err.message)
                        this.odesilani = false
                    })

        }
      }
    }

 }
</script>

