import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NapovedaView from '../views/NapovedaView.vue'
import FakturyView from '../views/FakturyView.vue'
import SmlouvyView from '../views/SmlouvyView.vue'
import InternetView from '../views/InternetView.vue'
import IPTVView from '../views/IPTVView.vue'
import UzivatelView from '../views/UzivatelView.vue'
// import LoginView from '../views/LoginView.vue'
import LogoutView from '../views/LogoutView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/uzivatel',
    name: 'uzivatel',
    component: UzivatelView,
    meta: {
      title: "Uživatel"
    }
  },
  {
    path: '/faktury',
    name: 'faktury',
    component: FakturyView,
    meta: {
      title: "Faktury"
    }
  },
  {
    path: '/smlouvy',
    name: 'smlouvy',
    component: SmlouvyView,
    meta: {
      title: "Smlouvy"
    }
  },
  {
    path: '/internet',
    name: 'internet',
    component: InternetView,
    meta: {
      title: "Služba internet"
    }
  },
  {
    path: '/iptv',
    name: 'iptv',
    component: IPTVView,
    meta: {
      title: "Služba iTV (IPTV)"
    }
  },
  {
    path: '/napoveda',
    name: 'napoveda',
    component: NapovedaView,
    meta: {
      title: "Nápověda"
    }
  },
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: LoginView,
  //   meta: { noAuth: true }
  // },
  {
    path: '/logout',
    name: 'logout',
    component: LogoutView,
    meta: {
      title: "Odhlášení"
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach((to) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title + " | Zákaznický portál Connect plus s.r.o."
  } else {
    document.title = "Zákaznický portál Connect plus s.r.o."
  }
})

// router.beforeEach((to, from, next) => {
//   var token = localStorage.getItem("token")
//   if (!to.meta.noAuth && !token) {
//     // console.log(from)
//     next({ name: 'login' })
//   }
//   else next()

// })

export default router
