<template>
    <v-dialog v-model="otevreno" width="600" :fullscreen="$vuetify.breakpoint.mobile">

        <v-card class="">

            <div class="text-overline pt-5" style="text-align:center">Změna hesla</div>
            
            <div class="body-1 font-weight-light pa-5" style="text-align:center">
              <p>Potvrďte, že chcete zaslat nové heslo. Napište 'ano' do kolonky níže. Nové heslo Vám přijde jako SMS na telefon.</p>
            </div>

            <v-form class="pa-4" ref="myForm">
              <v-text-field v-model="potvrzeni"  label="Potvrzení změny hesla - napište 'ano'" autofocus></v-text-field>
              <v-spacer class="pa-4"></v-spacer>

              <v-btn v-if="!odesilani" @click='odeslat' block>Odeslat</v-btn>
              <div v-if="odesilani" block style="text-align:center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </div>
            </v-form>
            <div class="text-overline pb-4" style="text-align:center" @click="otevreno=false"><a>Zavřít</a></div>
      </v-card>

    </v-dialog>
</template>

<script>
 export default {
    props: ['zobraz'],
    watch: {
      zobraz() {
        this.otevreno = true
        this.potvrzeni = ""
      },
    },
    data: () => ({
      odesilani: false,
      otevreno: false,
      potvrzeni: ""
     }),
    
    methods: {
      odeslat() {
        if (this.$refs.myForm.validate()) {
                this.odesilani = true
                let url = process.env.VUE_APP_API_URL + "/noveHeslo"
                let options = {
                  method: 'POST',
                  credentials: 'include',
                  headers: {'Accept': 'application/json','Content-Type': 'application/json','Authorization': localStorage.getItem("token")},
                  body: JSON.stringify({opravdu: this.potvrzeni})
                }
                fetch(url, options)
                    .then(res => {
                      if (res.status !== 200) {
                          throw new Error("Požadavek se nepodařilo odeslat.")
                      }
                      return res.json()
                    })
                    .then(() => {
                      // this.$emit('zavrit')
                      this.otevreno = false
                      this.$root.$emit('uspech', "Požadavek byl úspěšně odeslán. Nové heslo Vám přijde jako SMS na Váš telefon.")
                      this.odesilani = false
                    })
                    .catch((err) => {
                        this.$root.$emit('chyba', err.message)
                        this.odesilani = false
                    })

        }
      }
    }

 }
</script>

