<template>
    <div> {{ username }} </div>
</template>

<script>
    export default {
        data: () => ({
            username: ""
        }),
        
        methods: {
            reload() {
                this.loadUser()
            },

            loadUser() {
                let url = process.env.VUE_APP_API_URL + "/uzivatel"
                let options = {
                    credentials: 'include',
                    headers: {
                    'Authorization': localStorage.getItem("token"),
                    },
                }
                fetch(url, options)
                    .then(res => {
                        if (res.status === 401) {
                            this.$root.$emit('neprihlasen')
                            throw new Error("Uživatel odhlášen")
                        }
                        if (res.status !== 200) {
                            throw new Error("Chyba zpracování požadavku")
                        }
                        return res.json()
                    })
                    .then(dat => {
                        this.username = dat.uzivatel.Nazev
                    })
                    .catch(err => {
                        this.$root.$emit('chyba', err.message)
                    })
            }
        },

        mounted() {
            this.loadUser()
        }
    }
</script>

