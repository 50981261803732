<template>
    <v-dialog v-model="otevreno" width="600" :fullscreen="$vuetify.breakpoint.mobile">

        <v-card class="">

            <div class="text-overline pt-5" style="text-align:center">Upravení parametrů služby iTV</div>
            
            <div class="body-1 font-weight-light pa-5" style="text-align:center">
              <p>Po odeslání požadavku dojde ještě k prověření, zda je možné úpravu technicky realizovat. V případě problémů se Vám ozveme.</p>
              <p><a href="https://234.cz/jpg/files/533_connect_cenik_oboustranny.pdf" target="_blank">Ceník služby iTV</a></p>
            </div>

            <v-form class="pa-4" ref="myForm">
              <!-- <v-text-field v-model="form.Balicky"  label="Upravit vybrané balíčky" autofocus></v-text-field> -->
              <!-- <v-text-field v-model="form.MychX"  label="Upravit kanály mých 5/mých 10/mých 15"></v-text-field> -->

        <v-autocomplete
          v-model="form.Balicky"
          hint="Balíčky"
          :items="dostupne_balicky"
          persistent-hint
          dense
          single-line
          multiple
          chips
          deletable-chips
          @change="prepoctiBalicky"
        ></v-autocomplete>
        <v-autocomplete
          v-model="form.MychX"
          hint="Volitelné kanály (mých 5/10/15)"
          :items="dostupne_kanaly_keys"
          
          persistent-hint
          dense
          
          multiple
          chips
          deletable-chips
          @change="prepoctiBalicky"
        ></v-autocomplete>

        <v-autocomplete
          v-model="form.Stav"
          hint="Stav služby"
          :items="stavy"
          
          persistent-hint
          dense
          single-line
          chips
          deletable-chips
        ></v-autocomplete>

        <v-autocomplete
          v-model="form.SetTopBox"
          hint="Set-top box"
          :items="stbs"
          
          persistent-hint
          dense
          single-line
          chips
          deletable-chips
        ></v-autocomplete>


              <v-text-field v-model="form.Poznamka" hint="Vaše poznámka k požadavku" persistent-hint></v-text-field>

              <v-spacer class="pa-4"></v-spacer>

              <v-btn v-if="!odesilani" @click='odeslat' block>Odeslat</v-btn>
              <!-- <v-btn @click='pozastavit' block>Pozastavit službu</v-btn>
              <v-btn @click='vypnout' block>Vypnout službu</v-btn> -->
              <div v-if="odesilani" block style="text-align:center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </div>
            </v-form>
            <div class="text-overline pb-4" style="text-align:center" @click="otevreno=false"><a>Zavřít</a></div>
      </v-card>

    </v-dialog>
</template>

<script>
 export default {
    props: ['zobraz','itv_id', 'balicky'],
    watch: {
      zobraz() {
        this.otevreno = true
        this.form.Balicky = this.balicky
        this.form.MychX = []
        this.prepoctiBalicky()
      },
    },
    data: () => ({
      odesilani: false,
      otevreno: false,
      // no: false,
      dostupne_kanaly_keys: [],
      // menu: false,
      form: {
        Balicky: ["Start"]
      },

      dostupne_balicky: [
        "Cinemax",
        "Rodina",
        "Sport a zábava",
        "Volný čas",
        "Komplet",
        "PL free",
        "Russkie kanaly",
        "HBO",
        "Start",
        "Mých 5",
        "Mých 10",
        "Mých 15",
        ],
      dostupne_kanaly: {
"Cinemax HD, Cinemax 2 HD":2,
"Nickelodeon Junior":1,
"National Geographic Wild HD":1,
"Duck TV, Duck TV HD":1,
"Nova sport 1 HD, Nova Sport 2 HD":2,
"Auto Motor und Sport HD":1,
"Crime and Investigation":1,
"Sport 5 HD":1,
"club MTV":1,
"E! HD":1,
"AXN Black, AXN White":2,
"Minimax":1,
"Fishing & Hunting":1,
"Viasat Nature HD":1,
"Golf channel HD":1,
"Filmbox Premium HD":1,
"Markíza International HD":1,
"MTV":1,
"Eurosport 1 Czech HD, Eurosport 2 Czech HD":2,
"Viasat Explore HD":1,
"MTV Live":1,
"Viasat History HD":1,
"MTV Hits International":1,
"JimJam":1,
"Film Europe+ HD":1,
"Animal Planet HD, Discovery Channel Czech HD, Discovery Science HD, DTX, ID HD":5,
"Animal Planet HD, Discovery Science HD, DTX":3,
"AMC, AMC HD":1,
"National Geographic HD":1,
"Reality Kings HD":1,
"Spektrum HD":1,
"JOJ Cinema HD":1,
"Leo HD":1,
"Extreme Sports":1,
"MTV 90s":1,
"Sport 1 HD, Sport 2 HD":2,
"Arena Sport 1 HD":1,
"FilmBox Stars":1,
"AXN":1,
"Film Europe HD":1,
"English Club TV HD":1,
"Film+ HD":1,
"History":1,
"CS Film/CS Horror":1,
"Brazzers HD":1,
"Nova Sport 2 HD":1,
"CBS Reality":1,
"Nickelodeon":1,
"MTV 00s":1,
"TV Paprika":1,
"Nova sport 1 HD":1,
"MTV 80s":1,
"CNN Int.":1,
"Ocko Expres HD":1,
"Spektrum Home":1,
"Cartoon Network HD CZ":1,
"FilmBox Extra HD":1,
"FilmBox Family":1,
"Boomerang":1,
"Extasy HD":1,
    },
      stavy: ["Znovu spustit (aktivovat fakturaci)", "Pozastavit (pozastavit fakturaci)", "Zrušit (odpojit a demontovat)"],
      stbs: ["VIP 4205 - nový", "VIP 4205 - pronájem", "BOX VIP 1113 - repasovaný", "BOX VIP 1113 - pronájem"]
      
    }),

    mounted() {
      this.dostupne_kanaly_keys = Object.keys(this.dostupne_kanaly)
    },
    
    methods: {
      vypnout() {},
      pozastavit() {},
      prepoctiBalicky() {
        let c = 0
        if (this.form.MychX) 
          this.form.MychX.forEach((k) => {
            c += this.dostupne_kanaly[k]
          })
        let dopln = ""
        if (c > 0) dopln = "Mých 5"
        if (c > 5) dopln = "Mých 10"
        if (c > 10) dopln = "Mých 15"
        this.form.Balicky = this.form.Balicky.filter((v)=>v!="Mých 5")
        this.form.Balicky = this.form.Balicky.filter((v)=>v!="Mých 10")
        this.form.Balicky = this.form.Balicky.filter((v)=>v!="Mých 15")
        if (dopln) this.form.Balicky.push(dopln)
        if (this.form.Balicky.indexOf("Start") === -1)
          this.form.Balicky.push("Start")
        if (this.form.Balicky.indexOf("Komplet") !== -1) {
          this.form.Balicky = ["Start", "Komplet"]
          this.form.MychX = []
        }
        this.form.Balicky.sort((a,b)=>{if (a > b) return -1; return 1;})
        this.form.Balicky.sort(a=>{if (a === "Start") return -1; return 1;})
      },
      odeslat() {
        if (this.$refs.myForm.validate()) {
                this.form.idSluzby = this.itv_id
                // console.log(this.itv_id)
                this.odesilani = true
                let url = process.env.VUE_APP_API_URL + "/pozadavek"
                let options = {
                  method: 'POST',
                  credentials: 'include',
                  headers: {'Accept': 'application/json','Content-Type': 'application/json','Authorization': localStorage.getItem("token")},
                  body: JSON.stringify(this.form)
                }
                fetch(url, options)
                    .then(res => {
                      if (res.status !== 200) {
                          throw new Error("Požadavek se nepodařilo odeslat. Kontaktujte dispečink na tel.: 234 222 222")
                      }
                      return res.json()
                    })
                    .then(() => {
                      // this.$emit('zavrit')
                      this.otevreno = false
                      this.$root.$emit('uspech', "Požadavek byl úspěšně odeslán. O vyřízení Vás bude informovat dispečink.")
                      this.odesilani = false
                    })
                    .catch((err) => {
                        this.$root.$emit('chyba', err.message)
                        this.odesilani = false
                    })

        }
      }
    }

 }
</script>

