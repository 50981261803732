<template>
    <v-dialog v-model="otevreno" width="600" :fullscreen="$vuetify.breakpoint.mobile">

        <v-card class="">

            <div class="text-overline pt-5" style="text-align:center">Upravení parametrů služby internet</div>
            
            <div class="body-1 font-weight-light pa-5" style="text-align:center">
              <p>Po odeslání požadavku dojde ještě k prověření, zda je možné úpravu technicky realizovat. V případě problémů se Vám ozveme.</p>
            </div>

            <v-form class="pa-4" ref="myForm">
              <!-- 
                instalační adresa
                číslo bytu/místnosti
                rychlost
                typ služby (domacnost/firma)
                veřejná IP adresa
                pozastavit do
                spustit
               -->
              <v-text-field v-model="form.AdresaInstalace"  label="Instalační adresa" autofocus></v-text-field>
              <v-text-field v-model="form.Byt"  label="Číslo bytu/místnosti"></v-text-field>

        <v-autocomplete
          v-model="form.NovaRychlost"
          hint="Rychlost [Mbit/s]"
          :items="dostupna_rychlost"
          label="Rychlost [Mbit/s]"
          chips
          deletable-chips
          dense
          single-line
        ></v-autocomplete>
        <v-autocomplete
          v-model="form.VerejnaIP"
          hint="Veřejná IP adresa"
          :items="verejna"
          label="Veřejná IP adresa"
          chips
          deletable-chips
          dense
          single-line
        ></v-autocomplete>
        <v-autocomplete
          v-model="form.Router"
          hint="Router"
          :items="routery"
          label="Router"
          chips
          deletable-chips
          dense
          single-line
        ></v-autocomplete>
        <v-autocomplete
          v-model="form.ZmenitStav"
          hint="Změnit stav služby"
          :items="stavy"
          label="Změnit stav služby"
          chips
          deletable-chips
          dense
          single-line
        ></v-autocomplete>

              <!-- <v-switch v-if="stav === 'ONline'" v-model="form.Pozastavit" label="Pozastavit službu (pozastavení fakturace)"></v-switch> -->
              <!-- <v-switch v-if="stav === 'Pauza'" v-model="form.Spustit" label="Znovu spustit službu (aktivace fakturace)"></v-switch> -->
              <!-- <v-switch v-model="form.Zrusit" label="Zrušit službu (zastavit a demontovat zařízení)"></v-switch> -->
      <!-- <v-menu
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="form.Pozastavit"
            label="Pozastavit službu do data"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="form.Pozastavit"
          @input="menu = false"
        >
        </v-date-picker>
      </v-menu> -->

              <v-text-field v-model="form.Poznamka" hint="Vaše poznámka k požadavku" label="Jiný požadavek"></v-text-field>

              <v-spacer class="pa-4"></v-spacer>

              <v-btn v-if="!odesilani" @click='odeslat' block>Odeslat</v-btn>
              <!-- <v-btn @click='pozastavit' block>Pozastavit službu</v-btn>
              <v-btn @click='vypnout' block>Vypnout službu</v-btn> -->
              <div v-if="odesilani" block style="text-align:center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </div>
            </v-form>
            <div class="text-overline pb-4" style="text-align:center" @click="otevreno=false"><a>Zavřít</a></div>
      </v-card>

    </v-dialog>
</template>

<script>
 export default {
    props: ['zobraz','internet_id','dostupna_rychlost','stav','udaje'],
    watch: {
      zobraz() {
        this.otevreno = true
        for (var i = 0; i < this.udaje.length; i++) {
          if (this.udaje[i].Id == this.internet_id) {
            this.form = this.udaje[i]
            this.form.NovaRychlost = `${this.form.Download}/${this.form.Upload}`
            return
          }
          this.form = {}
        }

      },
    },
    data: () => ({
      odesilani: false,
      otevreno: false,
      // menu: false,
      form: {
      },

      // rychlosti: ["1000/1000","500/500","300/300","200/200","100/100","50/50","40/20","30/10"],
      verejna: ["Žádná", "1x NAT 1:1", "1x routovaná IP (použijí se 4 adresy)", "5x routovaná IP (použije se 8 adres)"],
      stavy: ["Znovu spustit (aktivovat fakturaci)", "Pozastavit (pozastavit fakturaci)", "Zrušit (odpojit a demontovat)"],
      routery: ["Router Wi-Fi 5", "Router Wi-Fi 6", "Router Wi-Fi 5 - pronájem", "Router Wi-Fi 6 - pronájem"],
      
      ruleSmlouva: [
        value => !!value || 'Musí být vyplněno.',
        value => {
          const pattern = /^[0-9]{5}[/]?[0-9]{2}[0-9]?$/
          return pattern.test(value) || 'Číslo smlouvy zadejte ve tvaru: 1234/12.'
        },
      ],
      ruleTelefon: [
        value => !!value || 'Musí být vyplněno.',
        value => {
          const pattern = /^[0-9]{9}$/
          return pattern.test(value) || 'Telefonní číslo zadejte ve tvaru: 123456789'
        },
      ]
    }),
    
    methods: {
      vypnout() {},
      pozastavit() {},
      odeslat() {
        if (this.$refs.myForm.validate()) {
                this.form.idSluzby = this.internet_id
                this.odesilani = true
                let url = process.env.VUE_APP_API_URL + "/pozadavek"
                let options = {
                  method: 'POST',
                  credentials: 'include',
                  headers: {'Accept': 'application/json','Content-Type': 'application/json','Authorization': localStorage.getItem("token")},
                  body: JSON.stringify(this.form)
                }
                fetch(url, options)
                    .then(res => {
                      if (res.status !== 200) {
                          throw new Error("Požadavek se nepodařilo odeslat. Kontaktujte dispečink na tel.: 234 222 222")
                      }
                      return res.json()
                    })
                    .then(() => {
                      // this.$emit('zavrit')
                      this.otevreno = false
                      this.$root.$emit('uspech', "Požadavek byl úspěšně odeslán. O vyřízení Vás bude informovat dispečink.")
                      this.odesilani = false
                    })
                    .catch((err) => {
                        this.$root.$emit('chyba', err.message)
                        this.odesilani = false
                    })

        }
      }
    }

 }
</script>

