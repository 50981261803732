<template>
  <v-container>
    <v-row>
      <v-col class="text-center"><h2>Nápověda</h2></v-col>

<v-expansion-panels class="font-weight-light">

  <v-expansion-panel>
     <v-expansion-panel-header class="text-overline">Varianty úhrady faktur</v-expansion-panel-header>
     <v-expansion-panel-content>
<p><b>Trvalý příkaz</b> - Nejpohodlnější způsob platby.  Pokud platíte měsíčně stejnou částku, můžete ve svém internetovém bankovnictví nastavit na tuto částku trvalý příkaz dle zaslané faktury. Při tomto způsobu nemusíte hlídat splatnost faktur. V případě, že se měsíční fakturovaná částka změní, nezapomeňte změnit i svůj trvalý příkaz.</p>
<p><b>Bankovním převodem</b> - Jedná se o klasický převod prostřednictvím <b>příkazu k úhradě</b>, který je možný provést v <b>kterékoliv bance</b>. Při použití této metody je nezbytně nutné uvedení správného čísla účtu a variabilního symbolu, kterým se podaří identifikovat Vaší platbu, také prosíme o zaslání přesné částky.</p>
<p><b>Poštovní poukázkou</b> - Jde o úhradu faktury prostřednictvím poštovní poukázky, na kterékoliv <b>pobočce České pošty</b>. Pro tento způsob platby žádáme kontrolu data splatnosti a zaslání platby s předstihem, protože částka se zpravidla připíše do 5ti pracovních dnů. Důležitá je také kontrola správnosti variabilního symbolu a čísla účtu.</p>
      </v-expansion-panel-content>
  </v-expansion-panel>

  <v-expansion-panel>
     <v-expansion-panel-header class="text-overline">Reklamace routeru</v-expansion-panel-header>
     <v-expansion-panel-content>
<p>Dopravte router poštou nebo osobně na sběrné místo: <b>V Zahradách 91, Bořanovice, 250 65</b>. Reklamace bude vyřízena do dvou měsíců. V případě osobní návštěvy je možné domluvit zápůčku náhradního routeru za <b>malý poplatek a vratnou kauci</b>.</p>
      </v-expansion-panel-content>
  </v-expansion-panel>

  <v-expansion-panel>
     <v-expansion-panel-header class="text-overline">Reset/změna hesla na wifi</v-expansion-panel-header>
     <v-expansion-panel-content>
<p>Postupujte dle manuálu pro konkrétní model routeru (každý router může mít trochu jiný postup):
<ul>
  <li><a href="https://www.tp-link.com/cz/faq-399.html">https://www.tp-link.com/cz/faq-399.html</a></li>
  <li><a href="http://www.netis-systems.com/Suppory/faq_detail/id/6.html">http://www.netis-systems.com/Suppory/faq_detail/id/6.html</a></li>
</ul>
</p>
<p>Na spodní straně routeru bývá štítek, kde jsou napsány základní údaje od výrobce: výchozí název wifi (SSID), výchozí heslo na wifi (PIN), výchozí IP adresa.</p>

<p>U většiny routerů funguje tento postup:
  <ul>
<li>připojit si k wifi routeru (pomocí kabelu, nebo přes wifi na původní heslo),</li>
<li>zadat do internetového prohlížeče (Internet Explorer, Firefox, Chrome atd…) adresu:<br>
- 192.168.0.1<br>
- nebo někdy: 192.168.1.1</li>
<li>pokud budou vyžadovány přístupové údaje (jméno/heslo či login/password) zkuste:<br>
- jméno/login: admin<br>
- heslo/password: admin</li>
<li>otevře se webové rozhraní administrace routeru a na první stránce nebo na stránce „wireless/security“ najdete současné heslo na wifi,</li>
<li>změňte heslo na nové požadované (více než 8 znaků) a uložte,</li>
<li>v zařízeních, které chcete na wifi připojit, bude vyžadováno zadání nového hesla,</li>
<li>pokud se některé zařízení stále připojuje přes staré heslo a nezeptá se na nové, tak je potřeba v tomto zařízení smazat stávající wifi (někdy nazvané jako: odstranit připojení k wifi nebo zapomenout připojení k wifi).</li>
  </ul>
</p>
      </v-expansion-panel-content>
  </v-expansion-panel>

  <v-expansion-panel>
     <v-expansion-panel-header class="text-overline">Změna routeru</v-expansion-panel-header>
     <v-expansion-panel-content>
<p>Měníte-li router nebo zařízení, které je přímo připojené k internetu, musí být z naší strany nejprve „aktivováno“. Pro aktivaci je nutné nastavit router tak, aby získal IP adresy dynamicky (pomocí DHCP) a aby bylo vypnuté klonování MAC (převážná většina nových routerů je takto již nastavena od výrobce a není nutné do konfigurace zasahovat). Následně potřebujeme znát MAC adresu nového zařízení, kterou nám můžete sdělit pomocí emailu nebo telefonicky.</p>
<p>MAC adresa bývá uvedena na krabici routeru nebo na štítku na spodní straně samotného routeru. Nicméně někteří výrobci routerů (například TP-Link) na obalech a štítkách neuvádí MAC adresu přesně. Je proto vhodné nový router připojit (i když nebude zatím funkční) a zavolat nebo poslat email s požadavkem na aktivaci nově připojeného zařízení. My toto zařízení (a jeho přesnou MAC adresu) dohledáme a dáme Vám zpět vědět, že aktivace je vyřízena. Poté už jen stačí router na chvíli vypnout. Po opětovném zapnutí je již přidělena správná konfigurace pro přístup do internetu.</p>
<p>Bližší informace k nastavení nového zařízení najdete pod bodem: „Nastavení nového routeru“</p>
      </v-expansion-panel-content>
  </v-expansion-panel>

  <v-expansion-panel>
     <v-expansion-panel-header class="text-overline">Nastavení nového routeru</v-expansion-panel-header>
     <v-expansion-panel-content>
<p>Nový router musí být z naší strany aktivován (viz bod „Změna routeru“). K tomu je potřeba router spustit v módu routeru (směrovač) nikoli v módu AP nebo bridge (most). Dále musí být router nastaven tak, aby získal IP adresy dynamicky (pomocí DHCP) a musí být vypnuto klonování MAC. Většina routerů je takto již nastavena od výrobce a není nutné tuto část konfigurace měnit. Výjimkou mohou být složitější routery nebo například routery kombinované s VDSL nebo LTE modemem (jejich nastavení může být složité a někdy nepředvídatelné – například co se týká priorizace WAN).</p>
<p>Zapojením routeru vzniká nová soukromá síť (ať již po kabelu nebo na wifi), do které „z venku“ (ze strany internetu) nelze zasahovat (síť je chráněna firewallem v routeru). Což mimo jiné znamená, že adresace a řízení této vnitřní sítě či nastavení a chování domácí wifi, je již plně v rukou zákazníka a z naší strany jako poskytovatele internetu není možné toto chování ovlivnit či měnit. Opět platí, že výchozí nastavení od výrobce bude nejspíše dostačující. Přesto některé parametry (například název wifi a heslo k wifi) si chce mnoho zákazníků nastavit podle svého. Pak je nutné postupovat dle pokynů ke konkrétnímu modelu routeru. Základní postupy jsou uvedeny v bodu: „Reset/změna hesla na wifi“.</p>
<p>V případě požadavku na složitější nastavení, nebo kdyby se vyskytl nějaký problém s nastavením, se můžete obrátit na náš dispečink a objednat výjezd technika, který na místě může pomoci. Tato služba je placená (pokud nejde o úvodní nastavení na nové přípojce). V individuálních případech lze domluvit vzdálenou pomoc s nastavením (router ale musí mít povelenu administraci „z venku“, abychom mohli konfiguraci dle požadavků provést).</p>
      </v-expansion-panel-content>
  </v-expansion-panel>

  <v-expansion-panel>
     <v-expansion-panel-header class="text-overline">Nefunguje mi internetové připojení</v-expansion-panel-header>
     <v-expansion-panel-content>
<p>Předtím, než budete volat na náš dispečink, zkuste udělat pár věcí, kterými byste si mohli pomoci svépomocí. Pokud nic z níže uvedeného nezabere, kontaktujte nás, a my se na to podíváme.</p>
<p class="text-overline">V žádném případě nepoužívejte tlačítko "reset", které se na routerech nachází. Tlačítko slouží pro vymazání nastavení routeru. Pro restartování router pouze odpojte od napájení.</p>
<p class="text-overline">Neměňte pořadí kabelů, které jsou do routeru zapojeny, problém to nevyřeší, ale mohlo by to způsobit další nechtěné komplikace.</p>

<ul>
<li>Zkontrolujte, zda jsou všechny kabely správně zapojeny do počítače, a přídpadně do WiFi routeru (blikající, nebo nepřerušovaně svítící diody jsou dobré znamení).</li>
<li>Zkuste připojení na jiném zařízení, například na mobilním telefonu, notebooku nebo tabletu. Pokud Vám nefunguje připojení přes WiFi, vyzkoušejte připojení na zařízení, které je připojeno přes kabel.</li>
<li>Zkontrolujte, jestli mají všechna zařízení, která instaloval poskytovatel, přívod napájení - nejčastěji se bude jednat o router/WiFi router, anténa, optický převodník. Zkontrolujte také, jestli funguje elektrika v domě, kde bydlíte, např. vyzkoušejte zda fungují světla na chodbě, výtah atd.</li>
<li>Restartuje zařízení, na který připojení nefunguje, dále restartujte router (vypojením ze zásuvky). Pokud jste připojeni pomocí technologie WiFi, zkuste také restartovat anténu (vypojením ze zásuvky).</li>
<li>Připojení vyzkoušejte na více www stránkách, například webové stránky poskytovatele, seznam, google, stránky se zpravodajstvím.</li>
<li>Zkontrolujte také zda-li má Vaše WiFi síť správné SSID (jméno sítě). Tuto kontrolu můžete provést napříkald z nastavení mobilního telefonu v záložce WiFi.</li>
</ul>
      </v-expansion-panel-content>
  </v-expansion-panel>

  <v-expansion-panel>
     <v-expansion-panel-header class="text-overline">Nenačítá se mi jedna konkrétní stránka</v-expansion-panel-header>
     <v-expansion-panel-content>
<p>Pokud nemůžete načíst jednu konkrétní webovou stránku, nebo stránka nefunguje tak, jak by měla, problém z 99% není v naší síti. Jedná se o problém na straně provozovatele www stránky, nebo hostingu. Proto není v našich možnostech problém vyřešit. Pokud máte tento problém, zkuste vyhledat jinou www stránku, ta nejspíše bude fungovat správně.</p>
      </v-expansion-panel-content>
  </v-expansion-panel>

   <v-expansion-panel>
     <v-expansion-panel-header class="text-overline">Zdá se mi, že mám pomalejší připojení, než mi náleží podle smlouvy</v-expansion-panel-header>
     <v-expansion-panel-content> 
<p>Rychlost připojení, kterou máte uvedenou ve smlouvě Vám garantujeme v bodě předání služby (na rozhraní). Nejčastěji se jedná o datovou zásuvku, router, kabel, nebo optický konvertor. Dbáme na to, aby veškerá nastavení přípojek byla v pořádku, ale přesto se občas chybička vloudit může.
<b>Než nás budete kontaktovat ohledně tohoto problému, zkuste změřit rychlost připojení ještě jednou, podle níže uvedených pokynů.</b></p>
<p>
  <ul>
<li>Pokud máte vlastní router, ověřte, zda podporuje takovou rychlost připojení, jakou má Váš tarif. Pokud jste router dostali při instalaci od našeho technika, tento krok můžete přeskočit, protože zařízení je optimální pro Váš tarif.
<li>Měření rychlosti provádějte na počítači, který:
  <ul>
<li>je k internetu připojený kabelem (přes WiFi není zajištěno úspěšné měření kvůli rušení),</li>
<li>je schopný pracovat na plný výkon (není v úsporném režimu),</li>
<li>má stažené a nainstalované poslední aktualizace ovladačů a operačního systému,</li>
<li>je připojený na nabíječku (v případě notebooků, nebo jiných přenosných zařízení),</li>
<li>nestahuje souběžně s měřením žádné objemné soubory (filmy, hry, torrenty atd.), nebo aktualizace systému,</li>
<li>nemá zapnuté žádné nestandartní služby, které by mohly omezovat rychlost připojení - např. VPN, vzdálená plocha,</li>
<li>má dostatečně výkonný procesor, který měření zvládne. U tarifů s vysokými rychlostmi může být měření ovlivněno výkonem procesoru daného zařízení (u zařízení nižší třídy - mobilní telefony, tablety, pc, netbooky), který není dostačující na měření,</li>
<li>nemá vysoce vytížený procesor - například procesem, který běží na pozadí (nejčastěji se může jednat o antivirus).</li>
</ul></li>
<li>Pokud nemáte možnost provést měření na zařízení, které je připojeno přes kabel, zajistěte alespoň přímou viditelnost mezi WiFi routerem a zařízením, na kterém měření provádíte. U tohoto způsobu však nelze očekávat stoprocentní výsledek, a není v našich silách s tím cokoliv udělat.</li>
<li>Pro test rychlosti využijte postupně alespoň 2 nezávislé měřící služby. Můžete využít tyto odkazy:
  <ul><li><a href="speedtest.net">Speedtest.net - Ookla</a></li>
  <li><a href="speedtest.net">Speedtest.234.cz - Connect</a></li>
  </ul></li>
<li>Zkontrolujte ostatní zařízení v síti. Rychlost vašeho připojení se dělí mezi všechna zařízení, která jsou připojena k Vaší síti. V praxi to znamená, že pokud chcete naměřit maximální rychlost, nesmí tato zařízení nic stahovat. Ideální je, aby ve chvíli, kdy probíhá měření, byla ostatní zařízení od sítě odpojena.</li>
<li>Test rychlosti vyzkoušejte alespoň ve 2 různých prohlížečích - Chrome, Firefox, Safari, Opera atd, pokud máte více zařízení, zkuste měření provést na více zařízeních.</li>
</ul>
<p>Pokud jste při měření dodržel/a výše uvedené tipy, a stále Vám měření ukazuje nižší rychlost, než máte mít podle smlouvy, ozvěte se nám a podíváme se na to společně. :)
</p>
      </v-expansion-panel-content>
  </v-expansion-panel>

   <v-expansion-panel>
     <v-expansion-panel-header class="text-overline">Zařízení se samovolně odpojuje od WiFi</v-expansion-panel-header>
     <v-expansion-panel-content> 
<p>V takovém případě je vhodné zkontrolovat aktualizace u problémového zařízení. Může se jednat o:
<ul>
  <li>aktualizace OS u mobilního zařízení nebo tabletu se systémem Android, WindowsMobile nebo iOS,</li>
  <li>aktualizace OS u počítače se systémem Windows, Linux nebo macOS,</li>
  <li>aktualizace ovladačů (např. síťové karty) - u počítačů s Windows, Linux nebo macOS.</li>
</ul>
</p>
<p>Dalším problémem může být dosah a útlum WiFi signálu, zařízení se může odpojovat ikdyž má zdánlivě dobrý signál. V takovém případě se může jednat pouze o odraz signálu. Zařízení vidí WiFi síť, připojí se, ale data přenášet nelze, tak se zařízení automaticky odpojí. Tento problém lze vyřešit tím, že se přesunete blíže k WiFi vysílači. Popřípadě najdete pro vysílač vhodnější místo. Idelání je střed oblasti, ze které se budete nejčastěji připojovat např. přibližný střed bytu. WiFi router by neměl být zavřený např. v kovové skříni, kvůli stínění signálu.</p>
<p>Vyzkoušejte, zda se bude za stejných podmínek odpojovat i jiné zařízení, než to, na kterém jste problém zaznamenali. Problém může být v jednotlivých zařízeních. Jak v klientském zařízení, tak ve WiFi routeru. Některé značky výrobců elektroniky špatně komunikují s některými WiFi vysílači. Snažíme se o to, abychom Vám dodávali zařízení, která mají co nejširší portfólio podporovaných značek a protokolů, ale občas se nějaká vyjímka bohužel najde. V případě, že WiFi nebude fungovat s nějakými Vašimi zařízeními, kontaktujte Nás a dohodneme se na řešení tohoto probému.</p>
      </v-expansion-panel-content>
  </v-expansion-panel>

   <v-expansion-panel>
     <v-expansion-panel-header class="text-overline">Nefunguje mi služba IPTV, nebo set-top box</v-expansion-panel-header>
     <v-expansion-panel-content> 
<p>První co je dobré zkusit, je odpojit set-top box od napájení, vyčkat 30 sekund, a znovu napájení zapojit.</p>
<p>Dále zkontrolujte, jestli je do set-top boxu správně docvaknutý datový kabel.</p>
<p>Pokud problém stále přetrvává, kontaktujte nás prosím.</p>
      </v-expansion-panel-content>
  </v-expansion-panel>

</v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    methods: {
      reload() {
      },
    }
  }
</script>
