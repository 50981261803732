
<template>
<!-- Vypise seznam faktur
 - nazev, datum vystaveni, datum splatnosti
 - castka
 - po splatnosti
 - zbyva zaplatit

 - filtrovat podle nezaplacenych
 - moznost stahnout fakturu v pdf
 - moznost otevrit detail faktury s QR kodem
-->
  <div>
    
    <v-container>
    <v-row class="text-center">
      <v-col><h2>Faktury</h2></v-col>
    </v-row>
     <v-row class="text-center">
      <v-col>
      <div class="d-flex justify-center">
      <v-checkbox
          v-model="neuhrazene"
          label="Zobrazit pouze neuhrazené"
        ></v-checkbox>
      </div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col xs="12" md="2">
        <v-autocomplete
          :items="roky"
          v-model="vybrany_rok"
          label="Rok"
          @change="reload"
        ></v-autocomplete>
      </v-col>
     </v-row>

<!-- 
    <v-row justify="center">
      <v-col md="4">
        <h2>Faktury</h2>
        <v-divider></v-divider>
      </v-col>
      <v-col md="4">
        <v-checkbox
          v-model="neuhrazene"
          label="Zobrazit pouze neuhrazené"
        ></v-checkbox>
      </v-col>
    </v-row> -->
    </v-container>
<!-- 
    <v-divider></v-divider>
    <div class="text-center pt-2">
    <v-btn
        color="primary"
        class="mr-2"
        @click="zobrazNezaplacene"
        :depressed="filtr==0"
      >
        Zobrazit jen nezaplacené
      </v-btn>
    <v-btn
        color="primary"
        class="mr-2"
        @click="zobrazVse"
        :depressed="filtr==-1"
      >
        Zobrazit vše
      </v-btn>
      </div> -->
    <br>  
    <v-expansion-panels class="mb-6" focusable>
          <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
          ></v-progress-linear>
      <div v-if="filtruj().length == 0"  class="text-overline mb-4"><span v-if="!loading">Žádné položky</span></div>
      <v-expansion-panel v-for="i in filtruj()"
        :key="i.Id">
        <v-expansion-panel-header disable-icon-rotate>
          <v-row >
            <v-col xs="6" md="4">
              <b>{{ i.DatVyst }}</b>
            </v-col>
            <v-col xs="6" md="4" class="text--secondary">
              VS:&nbsp;{{ i.VarSym }}
            </v-col>
            <v-col xs="6" md="4" class="text--secondary">
              Částka&nbsp;s&nbsp;DPH:&nbsp;{{ i.SumCelkem }}&nbsp;Kč
            </v-col>
          </v-row>

          <template v-if="i.ZbyvaUhradit>0" v-slot:actions>
            <v-icon color="error" title="Nezaplacená">
              mdi-alert-circle
            </v-icon>
          </template>
          <template v-else v-slot:actions>
            <v-icon color="teal" title="Zaplacená">
              mdi-check-circle
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
        
        <v-row justify="start">
        <v-col xs="12" md="6">
        
        <v-card class="mt-6">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5 mb-1">
                VS: {{ i.VarSym }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{i.Polozky}}
              </v-list-item-subtitle>


              <div class="text-overline mb-4 mt-4">
                Datum vystavení: {{ i.DatVyst }}
              </div>

              <div class="text-overline mb-4">
                Číslo účtu: {{ i.BankovniUcet }}
              </div>

              <div class="text-overline mb-4">
                Částka s DPH: {{ i.SumCelkem }} Kč
              </div>

              <div  class="text-overline mb-4">
                Zbývá úhradit:
                <v-chip
                  :color="getColor(i.ZbyvaUhradit)"
                  dark
                >
                  {{ i.ZbyvaUhradit }} Kč
              </v-chip>
              </div>

              <div  class="text-overline mb-4">Přeplatek: {{ i.SumPrepl }} Kč</div>
            </v-list-item-content>
            <!-- <v-list-item-content> -->
            
            <!-- </v-list-item-content> -->
        </v-list-item>
            <v-card-actions>
              <v-btn
                color="blue"
                rounded
                outlined
                @click="getPDF(i.Id)"
              >
                <v-icon left v-if="!downloading">mdi-download</v-icon>
                <span v-if="!downloading">Stáhnout PDF</span>
                <v-progress-circular
                  v-if="downloading"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-btn>
            </v-card-actions>
        </v-card>
        </v-col>

        <v-col xs="12" md="6"  v-if="i.ZbyvaUhradit>0 && i.BankovniUcet" class="mt-6">
          <!-- <qr-code class="d-flex justify-center" :text="getQR(i)"></qr-code> -->
          <!-- <qr-code class="d-flex justify-center" :text="getQR(i)"></qr-code> -->
          <qrcanvas :options="{cellSize: 6, data: getQR(i)}"></qrcanvas>
        </v-col>
        </v-row>

        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
<!-- 
  <v-data-table
    :headers="headers"
    :items="faktury"
    class="elevation-1"
    sort-by="DatVyst"
    :sort-desc="true"
    loading-text="Načítají se data"
    disable-pagination
    hide-default-footer
  >
    <template v-slot:item.ZbyvaUhradit="{ item }">
      <v-chip
        :color="getColor(item.ZbyvaUhradit)"
        dark
      >
        {{ item.ZbyvaUhradit }} Kč
      </v-chip>
    </template>
    <template v-slot:item.SumCelkem="{ item }">
       {{ item.SumCelkem }} Kč
    </template>
  </v-data-table> -->

  </div>
</template>

<script>
    // async function savePDF(kontext, fileid) {
    //       let url = process.env.VUE_APP_API_URL + "/faktura/" + fileid
    //       let options = {
    //         credentials: 'include',
    //         headers: {
    //           'Authorization': kontext.token,
    //         },
    //       }
    //       let response = await fetch(url, options)
    //       let dat = await response.blob()
    //       // const header = response.headers.get('Content-Disposition');
    //       // let filename
    //       // console.log(header)
    //       // if (header) {
    //       //   const parts = header.split(';');
    //       //   filename = parts[1].split('=')[1];
    //       // } else {
    //       //   filename = "faktura-"+fileid+".pdf"
    //       // }
    //       let filename = "faktura-"+fileid+".pdf"
    //       var a = document.createElement("a")
    //       a.href = window.URL.createObjectURL(dat)
    //       a.download = filename
    //       a.click()
    //       a.remove()
    //     //   console.log(dat.faktury)
    //       // dat.faktury.sort((a,b) => {if (a.DatVyst < b.DatVyst) return 1; else return -1})

    //       // kontext.faktury = dat.faktury
    // }

    // async function ale(kontext) {
    //       let url = process.env.VUE_APP_API_URL + "/faktury"
    //       let options = {
    //         credentials: 'include',
    //         headers: {
    //           'Authorization': kontext.token,
    //         },
    //       }
    //       let response = await fetch(url, options)

    //       if (response.status === 401) this.$root.$emit('neprihlasen')
    //       if (response.status !== 200) {
    //         throw new Error("Uživatel byl odhlášen")
    //       }
    //       let dat = await response.json()
    //     //   console.log(dat.faktury)
    //       if (!dat.faktury) {
    //         kontext.faktury = []
    //         return
    //       }
    //       dat.faktury.sort((a,b) => {if (a.DatVyst < b.DatVyst) return 1; else return -1})

    //       kontext.faktury = dat.faktury
    // }

  const { QRCanvas } = require('qrcanvas-vue');

  export default {
    name: 'FakturyView',
    components: {
      qrcanvas: QRCanvas,
    },
    data: () => ({
        faktury: [],
        neuhrazene: false,
        loading: true,
        downloading: false,
        token: "",
        vybrany_rok: new Date().getFullYear(),
        roky: [new Date().getFullYear()],
        headers: [
          {
            text: 'Položky',
            align: 'start',
            sortable: false,
            value: 'Polozky',
          },
          { text: 'Datum vystavení', value: 'DatVyst' },
          { text: 'Variabilní symbol', value: 'VarSym', sortable: false, },
          { text: 'Celkem', value: 'SumCelkem', sortable: false, },
          { text: 'Zbývá uhradit', value: 'ZbyvaUhradit', sortable: false, },
          { text: 'Bankovní účet', value: 'BankovniUcet', sortable: false, },
        ],
    }),

    mounted() {
      this.loadFaktury()
      this.rokyGen()
    },

    methods: {
      rokyGen() {
        let min = 2010
        let max = new Date().getFullYear()
        this.roky = []
        for (let i = max; i>=min; i--) {
          this.roky.push(i)
        }
      },

      reload() {
        this.loadFaktury()
      },

      // Nacte faktury - seradi podle data vystaveni a ulozi, po nacteni vypne indikaci loadovani
      loadFaktury() {
        let url = process.env.VUE_APP_API_URL + "/faktury/"+this.vybrany_rok
        let options = {
            credentials: 'include',
            headers: {
            'Authorization': localStorage.getItem("token"),
            },
        }
        fetch(url, options)
            .then(res => {
              if (res.status === 401) {
                  this.$root.$emit('neprihlasen')
                  throw new Error("Uživatel odhlášen")
              }
              if (res.status !== 200) {
                  throw new Error("Chyba zpracování požadavku")
              }
              return res.json()
            })
            .then(dat => {
              if (dat.faktury) dat.faktury.sort((a,b) => {if (a.DatVyst < b.DatVyst) return 1; else return -1})
              this.faktury = dat.faktury
              this.loading = false
            })
            .catch((err) => {
                this.$root.$emit('chyba', err.message)
            })
      },

      // Nacte PDF
      getPDF(fileid) {
        let url = process.env.VUE_APP_API_URL + "/faktura/" + fileid
        let options = {
            credentials: 'include',
            headers: {
            'Authorization': localStorage.getItem("token"),
            },
        }
        this.downloading = true
        fetch(url, options)
            .then(res => {
              if (res.status === 401) {
                  this.$root.$emit('neprihlasen')
                  throw new Error("Uživatel odhlášen")
              }
              if (res.status !== 200) {
                  throw new Error("Chyba zpracování požadavku")
              }
              return res.blob()
            })
            .then(dat => {
              let filename = "faktura-"+fileid+".pdf"
              var a = document.createElement("a")
              a.href = window.URL.createObjectURL(dat)
              a.download = filename
              a.click()
              a.remove()              
              this.downloading = false
            })
            .catch((err) => {
                this.downloading = false
                this.$root.$emit('chyba', err.message)
            })
      },

      // getPDF(f) {
      //   this.downloading = true
      //   savePDF(this, f.Id)
      //     .then(() => this.downloading = false)
      // },


      getColor(zbyva) {if (zbyva > 0) {return 'red'} else {return 'green'}},

      filtruj() {
        if (!this.faktury) return []
        if (this.neuhrazene) return this.faktury.filter((v)=>{return v.ZbyvaUhradit > 0})
        return this.faktury
        },

      getQR(f) {
        var sub, pref, ucet, banka, checksum, iban
        if (!f.BankovniUcet) return ""
        sub = f.BankovniUcet.split("-")
        pref = '000000'
        if (sub.length == 2) {
          pref = sub[0].padStart(6, '0')
        }
        ucet = sub[sub.length-1].split("/")[0].padStart(10, '0')
        banka = sub[sub.length-1].split("/")[1].padStart(4, '0')
      
        iban = banka + pref + ucet + '123500'
        while (iban.length > 10) {
          var part = iban.substring(0, 10);
          iban = (part % 97) +  iban.substring(10);          
        }
        checksum = String(98 - iban % 97).padStart(2, '0')

        iban = "CZ" + checksum + banka + pref + ucet
        // console.log(iban)
        
        var out = 'SPD*1.0*ACC:' + iban +'*AM:'+f.ZbyvaUhradit+'*CC:CZK*X-VS:'+f.VarSym
        return out
      }
    }

  }
</script>
