<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logoConnect.svg')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>
      <v-col><h2>Vítejte na zákaznickém portálu Connect plus s.r.o.</h2>
      <br>
      <br>
        <p>Legislativa:</p>
        <p><a href="https://234.cz/files/Podminky_prenositelnosti.pdf">Podmínky přenositelnosti čísel a změny poskytovatele služby přístupu k internetu – Connect plus s.r.o.</a></p>
        <p><a href="https://234.cz/files/VOP-od-2024-12-01.pdf">Prostudujte si všeobecné obchodní podmínky platné od 1.12.2024</a></p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    methods: {
      reload() {
      },
    }
  }
</script>
