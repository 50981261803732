<template>
  <div>
    
    <!-- <v-container>
    <v-row class="text-center">
      <v-col><h2>Služba - internet</h2></v-col>
    </v-row>
    </v-container>

    <br> -->
    <v-container>
    <v-row class="text-center">
      <v-col>
              <v-btn
                color="blue"
                rounded
                outlined
                @click="show_form ++; itv_id=0;balicky=['Start']"
              >
                <v-icon left>mdi-plus-circle</v-icon>
                <span>Zřídit novou službu iTV</span>
              </v-btn>
      </v-col>
    </v-row>
    </v-container>

        <v-row justify="center">
        <v-col xs="12" md="4" v-for="i in filtruj()" :key="i.Id">
        <v-card class="mt-6" :disabled="getStavDisable(i.Active)">
          <v-app-bar
            flat
            color="gray lighten-5"
          >
 
            <v-toolbar-title class="text-h6 pl-0">
              iTV (interaktivní TV)
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <!-- <v-icon>mdi-television</v-icon> -->
            <img src="../assets/itv.png">
           </v-app-bar>
          <v-list-item three-line>
            <v-list-item-content>

              <div class="text-overline mb-4">
                Stav: <span class="font-weight-light" :class="getStavColor(i.Active, i.Suspended)">{{ getStav(i.Active, i.Suspended) }}</span>
              </div>

              <div class="text-overline mb-4"  v-if="i.Offers">
                Balíčky: <span class="font-weight-light">{{ i.Offers }}</span>
              </div>

              <div class="text-overline mb-4"  v-if="i.Devices">
                Zařízení: <span class="font-weight-light">{{ i.Devices }}</span>
              </div>

              <div class="text-overline mb-4">
                Webové rozhraní: <span class="font-weight-light"><a target="_blank" href="https://sleduj.interaktivni.tv/">interaktivni.tv</a> - sledování TV z webového prohlížeče (kontaktujte dispečink, pokud nemáte přístupové údaje).</span>
              </div>

            </v-list-item-content>
            <!-- <v-list-item-content> -->
            
            <!-- </v-list-item-content> -->
        </v-list-item>

        <v-card-actions>
              <v-btn
                color="blue"
                rounded
                outlined
                @click="show_form ++; itv_id=i.Id; balicky=i.Offers.split(';\n')"
              >
                <v-icon left>mdi-wrench</v-icon>
                <span>Upravit paramery služby</span>
              </v-btn>
        </v-card-actions>

        </v-card>
        </v-col>

        </v-row>

    <p-form-change-itv :zobraz="show_form" :itv_id="itv_id" :balicky="balicky"></p-form-change-itv>
  </div>
</template>

<script>

  export default {
    name: 'IPTVView',
    data: () => ({
        itv: [],
        itv_id: 0,
        show_form: 0,
        loading: true,
        balicky: "",
        form_data: {}
    }),

    mounted() {
      this.loadITV()
    },

    methods: {
        reload() {
            this.loadITV()
        },

        loadITV() {
            let url = process.env.VUE_APP_API_URL + "/itv"
            let options = {
                credentials: 'include',
                headers: {
                'Authorization': localStorage.getItem("token"),
                },
            }
            fetch(url, options)
                .then(res => {
                if (res.status === 401) {
                    this.$root.$emit('neprihlasen')
                    throw new Error("Uživatel odhlášen")
                }
                if (res.status !== 200) {
                    throw new Error("Chyba zpracování požadavku")
                }
                return res.json()
                })
                .then(dat => {
                // if (dat.itv) dat.itv.sort((a,b) => {if (a.Stav < b.Stav) return 1; else return -1})
                this.itv = dat.itv
                this.loading = false
                })
                .catch((err) => {
                    this.$root.$emit('chyba', err.message)
                })
        },

        filtruj() {
            if (!this.itv) return []
            return this.itv
        },

        getStavColor(active, suspend) {
            if (active === 0) return 'red--text'
            if (suspend === 1) return 'orange--text'
            return 'green--text'
        },

        getStavDisable(stav) {
          if (stav === 1) return false
          return true
        },

        getStav(active, suspend) {
            if (active === 0) return 'Vypnuto'
            if (suspend === 1) return 'Pozastaveno'
            return 'Aktivní'
        },

    }

  }
</script>
