<template>
  <v-container>
    <v-row class="text-center">
      <v-col><h2>Odhlášení</h2></v-col>
    </v-row>
    <v-card flat class="d-flex align-center justify-center pa-4 mx-auto">
        <v-btn @click="odhlasit">Odhlásit se z portálu</v-btn>
    </v-card>
  </v-container>
</template>

<script>

  export default {
    name: 'LogoutView',
    methods: {
        odhlasit() {
            // pustit API na logout (zrusi token v databazi)
            let url = process.env.VUE_APP_API_URL + "/logout"
            let options = {
                credentials: 'include',
                headers: {
                'Authorization': localStorage.getItem("token"),
                },
            }
            fetch(url, options)
                .then(() => {
                    localStorage.removeItem("token")
                    // this.$router.push('/')
                    window.location.href = "/"
                })
                .catch(err=>console.log(err.message))
            // this.$emit('redir','/login')

        },

        reload() {
        },
    }

  }
</script>
